import http from "../utils/http";

export const retrieveInvoices = () => {
  return http.get("/invoices").then((resp) => resp.data);
};

export const retrieveAgentInvoices = (cgpId) => {
  return http
    .get(`/invoices?user.cgpId=${cgpId}`, {})
    .then((resp) => resp.data);
};
