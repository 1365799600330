import React, { useMemo } from "react";
import {
  Box,
  Breadcrumbs,
  Card,
  Container,
  Typography,
} from "@material-ui/core";
import InvoicesLibrary from "../../components/invoice/Invoicelibrary";
import { useQuery } from "react-query";
import { retrieveInvoices } from "../../http/invoices";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import AppCircularProgress from "../../components/AppCircularProgress";

const HubnupInvoices = () => {
  const invoicesQuery = useQuery({
    queryKey: ["admin-invoices"],
    queryFn: () => retrieveInvoices(),
  });

  const { 0: path } = useParams();
  const [year, month] = (path ?? "").split("/");

  const location = useLocation();
  const history = useHistory();

  function extractFilename(filePath) {
    return filePath.split("/").at(-1);
  }

  const invoices = useMemo(() => {
    if (!invoicesQuery.isSuccess) return [];

    const invoices = invoicesQuery.data["hydra:member"];

    if (!year)
      return [...new Set(invoices.map((item) => item.year))].map((item) => ({
        type: "folder",
        name: item.toString(),
      }));

    if (!month)
      return [
        ...new Set(
          invoices
            .filter((item) => item.year === year)
            .map((item) => item.month),
        ),
      ]
        .sort((a, b) => a - b)
        .map((item) => ({ type: "folder", name: item.toString() }));

    return invoices
      .filter((item) => item.year === year && item.month === month)
      .map((item) => ({
        type: "file",
        name: extractFilename(item.filename),
        data: item,
      }))
      .sort((a, b) => a.name - b.name);
  }, [invoicesQuery]);

  const handleClick = (file) => {
    if (file.type === "file") {
      console.log("File", file);
      window.open(
        `${process.env.REACT_APP_API_URL}${file.data.fileUrl}`,
        "_blank",
      );
    } else {
      history.push(`${location.pathname}/${file.name}`);
    }
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs separator="/" sx={{ mb: "1rem" }}>
        <Link to="/invoices/library">Factures</Link>
        {year && <Link to="/invoices/library/2024">{year}</Link>}
        {month && <Link to="/invoices/library/2024/12">{month}</Link>}
      </Breadcrumbs>
      <Card>
        {invoices.length > 0 ? (
          <InvoicesLibrary files={invoices} onView={handleClick} withAgent />
        ) : invoicesQuery.isLoading ? (
          <AppCircularProgress />
        ) : (
          <Box>
            <Typography sx={{ p: "2rem", textAlign: "center" }} component="div">
              Aucunes factures disponnibles
            </Typography>
          </Box>
        )}
      </Card>
    </Container>
  );
};

export default HubnupInvoices;
