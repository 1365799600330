import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { createDispatchInvoiceRequest } from "../../../http/dispatchInvoicesRequest";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { FolderOpen } from "@material-ui/icons";
import * as yup from "yup";

const CURRENT_YEAR = new Date().getFullYear();
const START_YEAR = 2020;

const FRENCH_MONTH = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const YEARS = Array.from({ length: CURRENT_YEAR - START_YEAR + 1 }).map(
  (_, index) => START_YEAR + index,
);

const schema = yup.object().shape({
  invoices: yup.mixed().required(),
  mapping: yup.mixed().required(),
  year: yup.string().required(),
  month: yup.string(),
});

function formatBytes(bytes) {
  const units = ["o", "Ko", "Mo", "Go", "To", "Po"];
  let i = 0;
  while (bytes >= 1024 && i < units.length - 1) {
    bytes /= 1024;
    i++;
  }
  return `${bytes.toFixed(2)} ${units[i]}`;
}

const DispatchInvoicesForm = ({ onValid }) => {
  const form = useForm({
    defaultValues: {
      invoices: null,
      mapping: null,
      year: CURRENT_YEAR,
      month: "year",
    },
    resolver: yupResolver(schema),
  });

  const [progress, setProgress] = useState({
    isUpload: false,
    percent: 0,
    debit: 0,
    loaded: 0,
    total: 0,
    timestamp: null,
  });

  const handleUploadProgress = (event) => {
    setProgress((state) => {
      const now = Date.now();
      const elapsedTime = (now - state.timestamp) / 1000; // en secondes

      return {
        isUpload: true,
        percent: (event.loaded / event.total) * 100,
        total: event.total,
        loaded: event.loaded,
        timestamp: now,
        debit: (event.loaded - state.loaded) / elapsedTime,
      };
    });
  };

  const createMutation = useMutation({
    mutationKey: ["create_dispatch_invoices_request"],
    mutationFn: (formData) =>
      createDispatchInvoiceRequest(formData, handleUploadProgress),
    onSuccess: () =>
      setProgress((state) => ({ ...state, isUpload: false, percent: 100 })),
    onError: () =>
      setProgress((state) => ({ ...state, isUpload: false, percent: 0 })),
  });

  const handleSubmit = form.handleSubmit(async (values) => {
    const formData = new FormData();

    formData.append("invoicesFile", values.invoices[0]);
    formData.append("mappingFile", values.mapping[0]);
    formData.append("year", values.year);
    if (values.month !== "year") formData.append("month", values.month);

    const response = await createMutation.mutateAsync(formData);

    form.reset();
    onValid(response);

    setProgress((state) => ({
      ...state,
      isUpload: false,
      timestamp: Date.now(),
      percent: 0,
    }));
  });

  const invoices = form.watch("invoices");
  const mapping = form.watch("mapping");

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<FolderOpen />}
                >
                  Télécharger les factures
                  <input
                    type="file"
                    hidden
                    accept=".zip"
                    {...form.register("invoices")}
                  />
                </Button>
                {invoices?.length > 0 && (
                  <>
                    <Typography>{invoices[0].name}</Typography>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<FolderOpen />}
                >
                  Télécharger le csv
                  <input
                    type="file"
                    hidden
                    accept=".xlsx"
                    {...form.register("mapping")}
                  />
                </Button>
                {mapping?.length > 0 && (
                  <>
                    <Typography>{mapping[0].name}</Typography>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel>Année</InputLabel>
                <Controller
                  name="year"
                  control={form.control}
                  render={({ field }) => (
                    <Select {...field}>
                      {YEARS.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel>Mois</InputLabel>
                <Controller
                  name="month"
                  control={form.control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="year">Toute l'année</MenuItem>
                      {FRENCH_MONTH.map((month, index) => (
                        <MenuItem key={index} value={index + 1}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <Button type="submit" variant="contained">
              Valider
            </Button>
          </Box>
          {progress.percent > 0 && (
            <>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}
              >
                <Typography>Débit : {formatBytes(progress.debit)}/s</Typography>
                <Typography>
                  Téléchargé: {formatBytes(progress.loaded)} /{" "}
                  {formatBytes(progress.total)}
                </Typography>
                <Typography>
                  Temps restant:{" "}
                  {Math.floor(
                    (progress.total - progress.loaded) / progress.debit,
                  )}{" "}
                  secondes
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={progress.percent}
                sx={{ my: 1 }}
              />
              <Alert severity="warning">
                Attention : ne changez/fermez pas la page au risque
                d'interrompre le téléchargement des fichiers.
              </Alert>
            </>
          )}
        </CardContent>
      </Card>
    </form>
  );
};

export default DispatchInvoicesForm;
