import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { Box, Container } from "@material-ui/core";

import Breadcrumbs from "../../components/Breadcrumbs";
import DispatchInvoicesForm from "../../components/invoice/dispatch/DispatchInvoicesForm";
import DispatchInvoicesRequestList from "../../components/invoice/dispatch/DispatchInvoicesRequestList";
import { fetchDispatchInvoiceRequests } from "../../http/dispatchInvoicesRequest";

const DispatchInvoices = () => {
  const dispatchInvoicesRequestQuery = useQuery({
    queryKey: ["dispatch_invoices_requests"],
    queryFn: () => fetchDispatchInvoiceRequests(),
  });

  const dispatchInvoicesRequest = useMemo(() => {
    if (dispatchInvoicesRequestQuery.isSuccess)
      return dispatchInvoicesRequestQuery.data["hydra:member"];

    return [];
  }, [dispatchInvoicesRequestQuery]);

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />
      <Box sx={{ gap: "2rem", display: "flex", flexDirection: "column" }}>
        <DispatchInvoicesForm
          onValid={() => dispatchInvoicesRequestQuery.refetch()}
        />
        <DispatchInvoicesRequestList
          dispatchInvoicesRequest={dispatchInvoicesRequest}
          isLoading={dispatchInvoicesRequestQuery.isLoading}
        />
      </Box>
    </Container>
  );
};

export default DispatchInvoices;
