import http from "../utils/http";

export const createDispatchInvoiceRequest = (formData, onUploadProgress) => {
  return http
    .post("/dispatch_invoice_request", formData, {
      onUploadProgress: onUploadProgress,
    })
    .then((resp) => resp.data);
};

export const fetchDispatchInvoiceRequests = () => {
  return http.get("/dispatch_invoices_requests").then((resp) => resp.data);
};
